<template>
   <v-dialog :value="!!customer_id" v-on:input="close" max-width="540" >
      <v-card>
         <v-card-title>
            Detail Pelanggan
            <v-spacer/>
            <v-icon color="red" @click="close">mdi-close</v-icon>
         </v-card-title>
         <v-divider class="mx-4 mb-4"></v-divider>
         <v-card-text>
            <div class="list-line">
               <label class="grey--text">ID</label>
               <div>{{fm.id}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Nama</label>
               <div>{{fm.name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Telp.</label>
               <div>{{fm.msisdn}} ({{fm.operator}})</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Email</label>
               <div>{{fm.email}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Gender</label>
               <div>{{fm.gender}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Agama</label>
               <div>{{fm.religion}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Pekerjaan</label>
               <div>{{fm.job}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Tempat/Tgl Lahir</label>
               <div>{{fm.birth_place}}/{{fm.birth_date}}</div>
            </div>
            <div class="list-line d-block">
               <label class="grey--text">Alamat</label>
               <div  class="text-right">
                  {{fm.address}}
               </div>
            </div>
            <div class="list-line">
               <label class="grey--text">Kodepos</label>
               <div>{{fm.zip}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Kota</label>
               <div>{{fm.city}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Provinsi</label>
               <div>{{fm.province}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Dibuat</label>
               <div  class="text-right">
                  {{fm.created_by && fm.created_by.name || '-'}}
                  <div class="caption" v-dater="fm.created_datetime" />
               </div>
            </div>
            <div class="list-line">
               <label class="grey--text">Diupdate</label>
               <div  class="text-right">
                  {{fm.updated_by && fm.updated_by.name || '-'}}
                  <div class="caption" v-dater="fm.updated_datetime" />
               </div>
            </div>
         </v-card-text>
         <!-- <v-card-actions class="justify-center">
            <v-btn v-if="edit" width="220" color="primary" outlined rounded @click="save">save</v-btn>
            <dir v-else>
               <v-btn class="mr-2" color="indigo" outlined @click="edit=true;title='Edit'"><v-icon>mdi-pencil</v-icon>edit</v-btn>
            </dir>
         </v-card-actions> -->
      </v-card>
   </v-dialog>
</template>
<script>
import api from '../api';
import CFG from '../config';
export default {
   props: ['customer_id'],
   data: () => ({
      title: 'Detail',
      fm: {},
      customer_image: null,
      edit: false,
      load_data: false,
      load_save: false,
      categorys: [],
      load_category: false,
      img_url: CFG.img_url,
   }),
   watch: {
      customer_id(val) {
         if(val) {
            this.title = 'Detail';
            this.edit = false;
            api.get(`customer/${val}`).then(rsl => {
               if(rsl.data) this.fm = rsl.data;
            });
         }
      }
   },
   mounted() {
   },
   methods: {
      close() {
         this.$emit('close');
         this.fm = {};
      },
   }
}
</script>
<template>
   <v-container>
      <crud
         api-path="customer"
         title="Pelanggan"
         :field="field"
         key-id="id"
         ref="crud"
      >
      <template v-slot:row_act="dat">
         <v-btn color="indigo" icon @click="view_id=dat.id" title="activate"><v-icon>mdi-eye</v-icon></v-btn>
      </template>
      </crud>
      <customer-detail :customer_id="view_id" v-on:close="view_id=null" />
   </v-container>
</template>

<script>
import crud from '@/components/crud';
import api from '../api';
import CustomerDetail from '../components/CustomerDetail.vue';

export default {
   components: { crud, CustomerDetail, },
   data: () => ({
      field: [
         { value: 'id', label: '#', ro: true, tbl: true },
         { value: 'name', label: 'Nama', tbl: true, searchKey: true },
         { value: 'msisdn', label: 'Telp', ro: true, tbl: true },
         { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
      ],
      actions: [
         {icon: 'edit', fn: 'openForm', color: 'indigo'},
      ],
      view_id: null
   }),
   methods: {
      activate(dat) {
         const val = dat.customer_active_status===1;
         this.$alert({ text: `${val?'Non-aktifkan':'Aktifkan'} kategori ini ?`, type: 'warning',
            ok: () => {
               api.edit('customer',`${dat.customer_id}/${val?'inactivate':'activate'}`).then(rsl => {
                  if(rsl.error) {
                     this.$alert('Ooops!', rsl.error, 'error');
                  }
                  if(rsl.success) {
                     this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000 });
                     this.$refs.crud.getData();
                  }
               });
            },
            cancel: () => {}
         });
      },
   }
};
</script>
